<template>
	<div class="layout-footer">
		<!-- <img alt="Logo" :src="footerImage()" height="20" class="mr-2" /> -->
		<span class="font-medium ml-2">{{appsName}} by PT. Kreasi Informatika Mandiri</span>
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		data() {
			return {		
				appsName: process.env.VUE_APP_APPS_NAME,
			}
		},
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? '/favicon.png' : '/favicon.png';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>