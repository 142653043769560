import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";

const routes = [
  {
    path: "/dashboard",
    name: "app",
    component: App,
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/Dashboard.vue"),
      },
      {
        path: "/project",
        name: "project",
        component: () => import("./pages/master/Project.vue"),
      },
      {
        path: "/project/:id/outlet-type",
        name: "project-outlet-type",
        component: () =>
          import("./components/master/project/outlet-type/Index2.vue"),
      },
      {
        path: "/project/:id/outlet",
        name: "project-outlet",
        component: () => import("./components/master/project/outlet/Index.vue"),
      },
      {
        path: "/project/:id/detail",
        name: "project-detail",
        component: () => import("./components/master/project/detail/Index.vue"),
        children: [
          {
            path: "/project/:id/detail/salesman-calculation",
            name: "project-detail-salesman-calculation",
            component: () =>
              import(
                "./components/master/project/detail/salesman-calculation/Index.vue"
              ),
          },
          {
            path: "/project/:id/detail/delivery-calculation",
            name: "project-detail-delivery-calculation",
            component: () =>
              import(
                "./components/master/project/detail/delivery-calculation/Index.vue"
              ),
          },
          {
            path: "/project/:id/detail/territory-mapping",
            name: "project-detail-territory-mapping",
            component: () =>
              import(
                "./components/master/project/detail/territory-mapping/Index.vue"
              ),
          },
          {
            path: "/project/:id/detail/territory-mapping/:clusterslot_id/by-numbers-of-beat",
            name: "project-detail-territory-mapping-by-numbers-of-beat",
            component: () =>
              import(
                "./components/master/project/detail/territory-mapping/beat/Index.vue"
              ),
          },
          {
            path: "/project/:id/detail/territory-mapping/:clusterslot_id/by-numbers-of-outlet",
            name: "project-detail-territory-mapping-by-numbers-of-outlet",
            component: () =>
              import(
                "./components/master/project/detail/territory-mapping/outlet/Index.vue"
              ),
          },
          {
            path: "/project/:id/detail/territory-mapping/:clusterslot_id/by-numbers-of-working-hours-per-day",
            name: "project-detail-territory-mapping-by-numbers-of-working-hours-per-day",
            component: () =>
              import(
                "./components/master/project/detail/territory-mapping/working-hours/Index.vue"
              ),
          },
        ],
      },
      {
        path: "/example-page",
        name: "example-page",
        component: () => import("./pages/ExamplePage.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("./pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !localStorage.getItem("authToken")) {
    next({ name: "login" });
  } else if (to.name === "login" && localStorage.getItem("authToken")) {
    next({ name: "dashboard" });
  } else {
    next();
  }
});

export default router;
